.about-us {
  background-color: #fff;
  padding: 50px 20px;
}

.container {
  width: 100%; /* Ensure the container takes up the full width */
  margin: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column; /* Ensures content flows top to bottom */
  align-items: center; /* Center-align content horizontally */
}

.intro-section,
.legacy-section,
.recognition-section,
.expertise-section,
.people-section,
.sustainability-section,
.why-choose-us-section,
.journey-section {
  width: 100%; /* Ensure each section takes up the full width of the container */
  text-align: center; /* Center text within each section */
  margin-bottom: 40px;
}

h1, h2 {
  color: #333;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 1.8em;
}

p {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
    padding: 20px;
  }

  h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 1.4em;
    margin-bottom: 15px;
  }

  p {
    font-size: 1em;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
    padding: 10px;
  }

  h1 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  p {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
}
