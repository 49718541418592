/* Hero Section */
.hero-investors {
  background-color: #f4f4f4;
  padding: 60px 0;
  text-align: center;
}

.hero-investors h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero-investors p {
  font-size: 18px;
  color: #666;
}

/* Introduction Section */
.introduction-investors {
  padding: 40px 0;
  text-align: center;
}

.introduction-investors h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.introduction-investors p {
  font-size: 16px;
  color: #666;
  max-width: 700px;
  margin: 0 auto;
}

/* Key Statistics Section */
.key-statistics {
  background-color: #e9ecef;
  padding: 40px 0;
  text-align: center;
}

.key-statistics h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.statistics-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.statistics-cards .card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  margin: 10px;
  text-align: center;
}

.statistics-cards .card h3 {
  font-size: 32px;
  color: #007bff;
  margin-bottom: 10px;
}

.statistics-cards .card p {
  font-size: 16px;
  color: #333;
}

/* Reasons to Invest Section */
.reasons-to-invest {
  padding: 40px 0;
  text-align: center;
}

.reasons-to-invest h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.reasons-to-invest ul {
  list-style-type: none;
  padding: 0;
  max-width: 700px;
  margin: 0 auto;
}

.reasons-to-invest ul li {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  text-align: left;
}

/* Call to Action Section */
.cta-investors {
  background-color: #007bff;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.cta-investors h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.cta-investors p {
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto 20px auto;
}

.cta-button {
  background-color: #fff;
  color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.cta-button:hover {
  background-color: #e9ecef;
}
