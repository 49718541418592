/* Global form styling */
form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

h3 {
  font-size: 20px;
  margin-top: 20px;
  color: #555;
}

div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

input[type="text"],
input[type="number"],
input[type="date"],
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

input[type="file"] {
  padding: 5px;
  margin-top: 10px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

/* Style individual owner sections */
.owner-section {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
}

.owner-section h3 {
  margin-bottom: 10px;
}

input[type="file"] {
  border: none;
  padding-left: 0;
}

@media (max-width: 600px) {
  form {
    width: 100%;
    padding: 10px;
  }

  input[type="text"],
  input[type="number"],
  input[type="date"],
  select {
    font-size: 14px;
  }

  button[type="submit"] {
    font-size: 14px;
    padding: 8px 16px;
  }
}
