/* Bottom Navigation Menu */
.bottom-nav {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 10px 0;
  z-index: 1000;
}

.bottom-nav-link {
  text-align: center;
  color: #333;
  font-size: 14px;
  text-decoration: none;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-nav-link span {
  margin-top: 5px;
  font-size: 12px;
}

.bottom-nav-link:hover {
  color: #007bff;
}

.bottom-nav .fa-icon {
  font-size: 20px;
}

/* Bottom Navigation Menu */
.bottom-nav {
  display: none; /* Hide by default */
}

@media screen and (max-width: 768px) {
  .bottom-nav {
    display: flex; /* Show on screens 768px or less */
  }
}
