.services-page {
  background-color: #fff;
  padding: 50px 20px;
}

.container {
  width: 80%;
  margin: auto;
  max-width: 1200px;
  line-height: 1.6;
}

.intro-section {
  margin-bottom: 40px;
  text-align: center;
}

.intro-section h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 2.2em;
}

.intro-section p {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555;
}

.service-section {
  margin-bottom: 40px;
}

.service-section h2 {
  color: #444;
  margin-bottom: 20px;
  font-size: 1.8em;
}

.service-section p {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
    padding: 20px;
  }

  h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 1.4em;
    margin-bottom: 15px;
  }

  p {
    font-size: 1em;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
    padding: 10px;
  }

  h1 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  p {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
}
