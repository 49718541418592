/* General Container */
.container {
  width: 80%;
  margin: auto;
  max-width: 1200px;
}

/* Hero Section */
.hero {
  background: url('../images/hero-background.jpg') no-repeat center center/cover;
  color: #fff;
  text-align: center;
  padding: 100px 20px;
}


.hero h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #e67e22;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
}

.cta-button:hover {
  background-color: #cf6d17;
}

/* Introduction Section */
.introduction {
  padding: 50px 20px;
  text-align: center;
}

.introduction h2 {
  color: #333;
  margin-bottom: 20px;
}

.introduction p {
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Services Overview Section */
.services-overview {
  background-color: #f4f4f4;
  padding: 50px 20px;
  text-align: center;
}

.services-overview h2 {
  color: #333;
  margin-bottom: 40px;
}

.service-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33% - 20px);
  box-sizing: border-box;
  border-radius: 10px;
  max-width: calc(33% - 20px);
}

.card h3 {
  color: #e67e22;
  margin-bottom: 15px;
}

.card p {
  line-height: 1.6;
}

/* Call to Action Section */
.cta-section {
  background-color: #333;
  color: #fff;
  padding: 50px 20px;
  text-align: center;
}

.cta-section h2 {
  margin-bottom: 20px;
}

.cta-section p {
  margin-bottom: 30px;
}

.cta-section .cta-button {
  background-color: #e67e22;
}

.cta-section .cta-button:hover {
  background-color: #cf6d17;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }

  .hero h1 {
    font-size: 2.5em;
  }

  .hero p {
    font-size: 1.1em;
  }

  .card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
  /* Bottom Navigation Menu */
.bottom-nav {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 10px 0;
  z-index: 1000;
}

.bottom-nav-link {
  text-align: center;
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

.bottom-nav-link:hover {
  color: #007bff;
}

.bottom-nav {
  background-color: red; /* Debugging color */
}
.bottom-nav {
  background-color: red; /* Debugging color */
}

}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
  }

  .hero h1 {
    font-size: 2em;
  }

  .hero p {
    font-size: 1em;
  }

  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

/* Footer */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  position: relative;
  bottom: 60px; /* Adjust the value to fit above the bottom navigation menu */
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}


.bottom-nav {
  display: none; /* Hide by default */
}




