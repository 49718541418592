/* Hero Section */
.hero-jobs {
  background-color: #f4f4f4;
  padding: 60px 0;
  text-align: center;
}

.hero-jobs h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero-jobs p {
  font-size: 18px;
  color: #666;
}

/* Working at Pioneer Section */
.working-at-pioneer {
  padding: 40px 0;
  text-align: center;
}

.working-at-pioneer h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.working-at-pioneer p {
  font-size: 16px;
  color: #666;
  max-width: 700px;
  margin: 0 auto;
}

.working-at-pioneer ul {
  list-style-type: none;
  padding: 0;
  max-width: 700px;
  margin: 20px auto 0 auto;
  text-align: left;
}

.working-at-pioneer ul li {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}

/* Available Positions Section */
.available-positions {
  background-color: #e9ecef;
  padding: 40px 0;
}

.available-positions h2 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.positions-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.position {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
}

.position h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.position p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.apply-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.apply-button:hover {
  background-color: #0056b3;
}

/* Call to Action Section */
.cta-jobs {
  background-color: #007bff;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.cta-jobs h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.cta-jobs p {
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto 20px auto;
}

.cta-button {
  background-color: #fff;
  color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.cta-button:hover {
  background-color: #e9ecef;
}
