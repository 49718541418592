.project-gallery {
  background-color: #fff;
  padding: 50px 20px;
}

.container {
  width: 80%;
  margin: auto;
  max-width: 1200px;
  line-height: 1.6;
}

.intro-section {
  margin-bottom: 40px;
  text-align: center;
}

.intro-section h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 2.2em;
}

.intro-section p {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.gallery-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  padding: 15px;
  transition: transform 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.gallery-item h3 {
  color: #333;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.gallery-item p {
  color: #555;
  font-size: 1em;
  margin-bottom: 15px;
}

.gallery-item:hover {
  transform: translateY(-5px);
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
    padding: 20px;
  }

  h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.3em;
  }

  p {
    font-size: 1em;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
    padding: 10px;
  }

  h1 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 0.9em;
  }
}
