.navbar {
  width: 100%;
  background-color: #333;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.menu-icon {
  display: none;
  cursor: pointer;
  color: #fff;
}

.logo img {
  max-height: 100px; /* Increase the height to make the logo larger */
  transition: all 0.3s ease;
  padding: 0px; /* Add some padding to give it more space */
}

/* Default: Logo on the right and menu icon on the left for small screens */
@media (max-width: 767px) {
  .menu-icon {
    display: block;
    position: absolute;
    left: 10px;
    top: 15px; 
  }

  .logo {
    order: 2;
    margin-left: auto;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    width: 100%;
    text-align: left;
  }

  .nav-links li a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
  }
}

/* For larger screens: Logo on the left and menu on the right */
@media (min-width: 768px) {
  .logo {
    order: 1;
    margin-right: auto;
  }

  .menu-icon {
    display: none;
  }

  .nav-links {
    order: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: left;
    gap: 20px;
  }

  .nav-links li {
    display: inline;
  }

  .nav-links li a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
  }
}
