.header {
    background-color: red;
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar {
    background-color: #f0f0f0;
    padding: 20px;
    width: 250px;
    min-height: 100vh;
}

.main-content {
    flex: 1;
    padding: 20px;
}

.content {
    display: flex;
}

a {
    display: block;
    margin: 10px 0;
    color: green;
}

button {
    background: none;
    border: none;
    padding: 5px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #ddd;
}
