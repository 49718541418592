.contact-page {
  background-color: #f9f9f9;
  padding: 60px 20px;
}

.container {
  width: 85%;
  margin: auto;
  max-width: 1200px;
  line-height: 1.8;
  color: #333;
}

.intro-section {
  margin-bottom: 50px;
  text-align: center;
}

.intro-section h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 2.5em;
  font-weight: 700;
}

.intro-section p {
  margin-bottom: 30px;
  font-size: 1.2em;
  color: #666;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.contact-info {
  margin-bottom: 60px;
  text-align: center;
}

.contact-info h2 {
  color: #444;
  margin-bottom: 30px;
  font-size: 2em;
  font-weight: 600;
}

.contact-method {
  margin-bottom: 30px;
}

.contact-method h3 {
  color: #333;
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: 500;
}

.contact-method p {
  color: #666;
  font-size: 1.1em;
}

.contact-method a {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.contact-method a:hover {
  text-decoration: underline;
}

.contact-form {
  margin-top: 40px;
}

.contact-form h2 {
  color: #444;
  margin-bottom: 30px;
  font-size: 2em;
  font-weight: 600;
  text-align: center;
}

.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1.1em;
  color: #444;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1.1em;
  color: #333;
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin: 0 auto;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
    padding: 20px;
  }

  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.8em;
    margin-bottom: 25px;
  }

  h3 {
    font-size: 1.3em;
  }

  p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }

  input, textarea {
    font-size: 1em;
  }

  button[type="submit"] {
    font-size: 1em;
    padding: 12px 20px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
    padding: 10px;
  }

  h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 1em;
  }

  input, textarea {
    font-size: 0.9em;
  }

  button[type="submit"] {
    font-size: 0.9em;
    padding: 10px 15px;
  }
}
